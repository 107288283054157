.footer {

    &-wrapper {
        padding-top: 6em;
        padding-bottom: 6em;
        width: 100%;
    }

    &-grid {
        display: grid;
        //column-gap: 2em;
        row-gap: 1em;
        grid-template-columns: 1fr 1fr ;
        grid-template-areas:
            "footer-logo footer-logo"
            "footer-baseline footer-baseline";
    }

    &-logo {
        height: 80px;
    }

    &-adress {
        grid-area: footer-logo;
        justify-self: center;
        display: flex;
        align-items: center;

        h5 {
            margin-left: 1em;
            line-height: 18px;
        }
        //margin-bottom: 1em;
    }

    &-legal {
        grid-area: footer-legal;
        display: flex;
    }

    &-social {
        grid-area: footer-social;
        display: flex;
        flex-wrap: wrap;
        //margin-left: 1em;
    }
    &-social_item {
        margin: 0 0.5em;
    }
    &-phone {
        display: flex;
    }
}

.footer-baseline {
    grid-area: footer-baseline;
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;

}
/*
@media (max-width: 480px) {
  .client-grid {
    grid-template-columns: 1fr ;
    //grid-template-rows: 1fr 1fr 1fr;
  }
  
}
  */
/* Small devices (phones, 481px and up) */
@media (min-width: 0px) and (max-width: 820px) {
    /* Your styles here */
    .footer-grid {
      grid-template-columns: 1fr;
      grid-template-areas:
        "footer-logo"
        "footer-baseline";
    }
    .footer-baseline {
        flex-direction: column;
        gap: 2em;
    }
    .footer-social {
        justify-self: center;
    }
    .footer-legal {
        justify-self: center;
    }
  }