.client-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 6em;
  padding-bottom: 6em;
}

.client-grid {
  display: grid;
  column-gap: 2em;
  row-gap: 2em;
  grid-template-columns: 400px 1fr ;
  grid-template-areas:
    "client-title client-title"
    "client-description client-icons";
}
.client-title {
  grid-area: client-title;
}
.client-description {
  display: flex;
  flex-direction: column;
  grid-area: client-description;
}

.client-icons {
  display: flex;
  grid-area: client-icons;
}
.client-button {
  //grid-area: client-button;
  margin-top: 2em;
}

.client-list {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin: 0;

  
}
/*
@media (max-width: 480px) {
  .client-grid {
    grid-template-columns: 1fr ;
    //grid-template-rows: 1fr 1fr 1fr;
  }
  
}
  */
/* Small devices (phones, 481px and up) */
@media (min-width: 0px) and (max-width: 820px) {
  /* Your styles here */
  .client-grid {
    gap: 2em;
    grid-template-columns: 1fr;
    grid-template-areas:
      "client-title"
      "client-icons"
      "client-description";
  }
  .client-description {
    align-items: center;
  }
  .client-list {
    flex-wrap: wrap;
  }
  .client-list * + * {
    margin-top: 2em;
  }
}
