.FAQ {

  &-wrapper {
    position: relative;
    width: 100%;
    height: auto;
   //padding-top: 6em;
    padding-top: 6em;
    padding-bottom: 6em;
  }
  
  &-grid {
    display: grid;
    gap: 2em;
    //justify-items: start;
    grid-template-columns: 300px 1fr ;
    grid-template-areas:
      "phone content";
  }

  &-title {
    margin-bottom: 2em;
  }

  &-content {
    //display: flex;
    //gap: 2em;
    grid-area: content;
    h2 {
      margin-top: 1em;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-image {
    width: 300px;
    border-radius: 20px;
    grid-area: phone;
  }

  &-list {
    margin-top: 2em;
  }

}

@media (max-width: 480px) {
  .FAQ-grid {
    grid-template-columns: 1fr ;
    grid-template-areas:
      "phone "
      "content";
  }
}
/* Small devices (phones, 481px and up) */
@media (min-width: 481px) and (max-width: 820px) {
  /* Your styles here */

}

