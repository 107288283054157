.images {
    position: relative;
    width: 100%;
    display: block;
    
    &-wrapper {
        
        position: relative;
        padding-top: 4em;
        padding-bottom: 2em;
        //background: #fff;
        //transform: translateY(-8em);
    }
    
    &-content {
        position: relative;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;

        //transform: translateY(-6em);
    }
    &-text {
        display: none;
        //display: none;
        width: 100%;
        height: auto;
        position: absolute;

        background: rgba($color: #222, $alpha: 1.0);

        transform: translateY(100%);
        
        padding: 1em;

        bottom: 0;
        left: 0;

        h5 {
            color: white;
        }
        h3 {
            color: var(--accent-color);
            margin-bottom: 0.3rem;
        }
    }
}

.image-grid {
    display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(12, 150px);
  margin: 0 auto;
  max-width: 90%;
  padding: 3rem 0;

    img{
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
}

.images-content_in {
    .images-text {
        animation: 300ms text-hover_in ease-in-out forwards;
    }
    img {
        animation: 400ms image-hover_in ease-out forwards;
    }
    
    //animation-direction: reverse;
}
.images-content_out {
    .images-text {
        //
        animation: 300ms text-hover_out ease-in-out forwards;
    }
    img {
        animation: 400ms image-hover_out ease-out forwards;
    }
    
    //animation-direction: reverse;
}

@keyframes image-hover_in {
    from {
        filter: grayscale(1.0);
        transform: scale(1.0);
    }
    to {
        filter: grayscale(0.2);
        transform: scale(1.05);
    }
}
@keyframes image-hover_out {
    from {
        filter: grayscale(0.2);
        transform: scale(1.05);
    }
    to {
        filter: grayscale(1.0);
        transform: scale(1.0);
    }
}
@keyframes text-hover_in {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}
@keyframes text-hover_out {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(100%);
    }
}
/*
@media (prefers-reduced-motion: no-preference) {

    .images-content {
      transform: translateY(7em);
      animation: translateMe linear forwards;
      animation-timeline: view();
      animation-range-start: 50px;
      animation-range-end: 200px;
    }

    @keyframes translateMe {
      to { transform: translateY(0em);}
    }
  }
*/