.carousel2-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

.carousel2-content {
  display:flex;
  position: relative;
  height: 100%;
}
  
.carousel2-image {
  position: absolute;
  height: 100%;
  filter: grayscale(0.30);
  opacity: 0;
  //transform: translateX(-100px);
}
  