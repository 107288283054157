
/* COLORS */

:root {
  --primary-color: #EFEFEF;
  --secondary-color: #222;
  --accent-color: #C9B39B;

  --black-color: #000;
  --white-color: #FFF;

  --green-color: #248320;
  --orange-color: #eb8800;
  --red-color: #C12525;

  --content-max-width: 1024px;
  --content-min-width: 820px;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  list-style-type: none;
  box-sizing: border-box;
}

body {
  //background: var(--primary-color);
  background: var(--secondary-color);
  //user-select: none;
}
aside {
  background: var(--black-color);
}
section {
  position: relative;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  letter-spacing: -1px;
  line-height: 40px;
  font-weight: 500;
  color: var(--accent-color);
}
h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 2rem;
  font-weight: 400;
  color: var(--accent-color);
}
h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  letter-spacing: -.5px;
  line-height: 20px;
  font-weight: 400;
  color: var(--accent-color);
}
h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  font-weight: 400;
  text-wrap: balance;
  color: var(--accent-color);
}
h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 16px;
  font-weight: 400;
  color: var(--secondary-color);
}
p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  font-weight: 300;
  color: var(--secondary-color);

  //text-align: justify;
  text-wrap: pretty;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

a {
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

li {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

label {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  letter-spacing: -.5px;
  line-height: 20px;
  font-weight: 400;
  //color: var(--accent-color);
}
.text-light {
  color: var(--primary-color);
}
.text-white {
  color: white;
}
.text-dark {
  p {
    font-weight: 300;
    color: var(--secondary-color);
  }
  color: var(--secondary-color);
}
.text-accent {
  color: var(--accent-color);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.hide {
  display: none;
}
.hidden {
  visibility: hidden;
}
.overflow {
  overflow: hidden;
}
.mask {
  clip-path: polygon(0.00% 0.00%,100% 0%,100% 100%,0% 100%);
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-left {
  align-items: flex-start;
}
.flex-top {
  justify-content: flex-start;
}


.width-100 {
  width: 100%;
  position: relative;
}

.constrain-width {
  width: 100%;
  max-width: var(--content-max-width);
  //min-width: var(--content-max-width);
}

.col-2 {
  //transform: translateY(-200px);
  position: relative;
  display: flex;
  flex-wrap: wrap;

  flex-direction: row;
  
  & > * {
  position: relative;
  flex: 1 1 50%;
  //background: blue;
  }
  
}
.col-3 {
  //transform: translateY(-200px);
  position: relative;
  display: flex;
  flex-wrap: wrap;

  flex-direction: row;
  
  & > * {
  position: relative;
  flex: 1 1 33.33%;
  //background: blue;
  }
  
}

.padding-sides {
  padding: 0 3em;
}

.title {
  position: relative;
  display: inline-block;

  //background: red;

  *:first-child {
      margin-bottom: 0.5em;
      //letter-spacing: -2px;
  }
  h1 {
    letter-spacing: -2px;
  }
}

.line {
  width: 100%;
  height: 2px;
  background: var(--accent-color);
}

.line-dark {
  background: var(--accent-color);
}

.triangle-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 22;
  transform-origin: right top;
  transform: scale(-1, -0.05) translate(101%, -98%);
  //height: 10%;
}
.triangle-bottom {
  position: absolute;
  opacity: 1.0;
  bottom: 0;
  width: 100%;
  transform-origin: bottom right;
  transform: scaleY(0.05);
  //height: 10%;
}

.white-bg {
  background: white;
}
.dark-bg {
  background: black;
}
.accent-bg {
  background: var(--primary-color);
}

.moire {
  //display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.button-primary {
  //background: var(--accent-color);
  //border: solid 1px var(--accent-color);
  //border-radius: 3px;
  padding: 0.1em 2em;
}

.bg-red {
  background-color: red;
}

.card-list * + * {
  margin-top: 1em;
}

.annexe {
  margin-top: 10em;
  margin-bottom: 6em;
  p {
    color: black;
  }

  h2 {
    color: black;
    margin-bottom: 2em;
  }
  h3 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.annexe-question {
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 0.5em;
}

.annexe-menu {
  display: flex;
  gap: 0.5em;
  margin-bottom: 2em;
}

/*----- animations -----*/

/*
@media(prefers-reduced-motion: no-preference){

  .scroll-animation-black > span {
      color: rgba(0, 0, 0, 0);
      background-image: linear-gradient(90deg, #222, #222);
  }
  .scroll-animation-accent > span {
    color: rgba(201, 179, 155, 0);
    background-image: linear-gradient(90deg, #C9B39B, #C9B39B);
}

  .scroll-animation-white > span {
      color: rgba(255, 255, 255, 0);
      background-image: linear-gradient(90deg, white, white);
  }

  .scroll-animation > span {
      
      background-clip: text;
      background-repeat: no-repeat;
      background-size: 0% 100%;
      
      animation: scroll-animation linear forwards;
      animation-timeline: view();
      animation-range-start: 100px;
      animation-range-end: 200px;
      
  }

  .title-animation {
      //opacity: 0;
      transform: translateY(200%);
      animation: fade-translate linear forwards;
      animation-timeline: view();
      animation-range-start: 100px;
      animation-range-end: 300px;
  }
  .line-animation {
      scale: 0 1;
      transform-origin: left;
      animation: scale linear forwards;
      animation-timeline: view();
      animation-range-start: 50px;
      animation-range-end: 200px;
  }
  .fade-animation {
      opacity: 0;
      animation: fade linear forwards;
      animation-timeline: view();
      animation-range-start: 100px;
      animation-range-end: 300px;
  }
  @keyframes scroll-animation {
      to {
          background-size: 100% 100%
      }
  }
  @keyframes fade-translate {
      100% {
          //opacity:1;
          transform: translateY(0);
      }
  }
  @keyframes fade {
      100% {
          opacity:1;
      }
  }
  @keyframes scale {
      100% {scale:1 1;}
  }
  
}
*/
/******* responsive *********/
/* Extra small devices (phones, 480px and down) */
@media (max-width: 480px) {
  /* Your styles here */
  body {
    //background: red;
  }
}

/* Small devices (phones, 481px and up) */
@media (min-width: 481px) and (max-width: var(--content-min-width)) {
  /* Your styles here */
  body {
    //background: red;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Your styles here */
  body {
    //background: blue;
  }
}

/* Large devices (laptops, 1025px and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1201px and up) */
@media (min-width: 1201px) {
  /* Your styles here */
}