.service {
    &-wrapper {
        
        li {
            color: var(--accent-color);
        }

        padding-top: 9em;
        padding-bottom: 6em;
    }
    &-title {
        grid-area: service-title;
        
    }
    &-header {
        
        position: relative;
        //display: flex;
        //flex-direction: column;
        //align-items: flex-end;
        
        p {
            margin-top: 1em;
        }
        /*
        .title {
            flex-shrink: 0;
        }
        */
        padding-bottom: 3em;
    }
    
    &-photo {
        display: none;
        grid-area: service-photo;
        position: relative;
        width: 100%;
        height: 100%;
        //background: grey;
        //overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            //object-position: right;
            border-radius: 0px 10px 10px 0px;
        }
    }
    &-content {
        grid-area: service-content;
    }

    &-icons {
        display: flex;
        justify-content: space-evenly;
        margin-right: 1em;
    }

    &-right {
        position: relative;
        width: 100%;
        height: 100%;
        grid-area: service-right;
    }
    &-right__line {
        width: 1px;
        height: 100%;
        background: var(--accent-color);
        opacity: 0.0;
    }
    /*
    &-right::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background: var(--accent-color);
    }
    */
}

h4 {
    margin: 1em 0;
}

  /* Small devices (phones, 481px and up) */
@media (min-width: 0px) and (max-width: 820px) {
    /* Your styles here */
    .service-header {

        flex-direction: column;
        align-items: flex-start;

        p {
            margin-top: 2em;
            margin-left: 0em;
        }
        /*
        .title {
            flex-shrink: 0;
        }
        */
    }
}

