.phone-wrapper {
  position: relative;
  overflow: hidden;
  width: 300px;
  border-radius: 26px;
}

.phone-content {
  display:flex;
  position: relative;
  width: 300px;
  //height: 100%;
}
  
.phone-image {
  position: relative;
  width: 300px;
  //height: 100%;
  filter: grayscale(0.30);
  //transform: translateX(-100px);
}
/*
@media (prefers-reduced-motion: no-preference) {
  .phone-wrapper {
    //transform: translateY(7em);
    animation: phone-translate linear forwards;
    animation-timeline: view();
    animation-range-start: 0;
    animation-range-end: contain;
  }
  @keyframes phone-translate {
    from {transform: translateY(7em);}
    to { transform: translateY(-7em);}
  }
}
*/