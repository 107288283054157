.intro-tagline {
    opacity: 0;

    &__wrapper {
        position: relative;
        overflow: hidden;
        
        h4 {
            margin: 0;
            line-height: 25px;
        }
    }
}