
.date {
  h5 {
    margin-top: 0.5em;
  }
}

.ant-picker {
    width: 100%;
    height: 50px;
    margin-top: 1em;
    margin-bottom: 0;
}

/* Optional: Change placeholder color */
.ant-picker .ant-picker-input input::placeholder {
    color: black; /* Change placeholder text color */
}
/* Optional: Change the suffix icon color */
.ant-picker .ant-picker-input .ant-picker-suffix {
    color: var(--accent-color); /* Change icon color */
}

.ant-picker-outlined {
    border: solid 1px var(--accent-color);
}

/* Change the background and text color of the selected date */
.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--accent-color)!important; /* Replace with your desired color */
    color: red; /* Text color */
  }
  
  /* Change the border color of the selected date */
  .ant-picker-cell-selected .ant-picker-cell-inner::after {
    border-color: red; /* Same color as the background or another color for the border */
  }
  
  /* Change the hover effect color on the selected date */
  .ant-picker-cell-selected:hover .ant-picker-cell-inner {
    background-color: red; /* Slightly different for hover effect */
    color: #fff;
  }
  
  /* Adjust styles for the DatePicker dropdown if needed */
  .ant-picker-dropdown {
    /* Custom styles for the dropdown, if necessary */
  }