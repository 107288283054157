.app-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 0em;
  padding-bottom: 9em;
}

.app-grid {
  display: grid;
  gap: 2em;
  //justify-items: start;
  grid-template-columns: 1fr 450px ;
  grid-template-rows: auto;
  grid-template-areas:
    "app-title app-title"
    "app-description app-description"
    "app-icons_grid QR";
    //padding-top: 3em;
}

.app-title {
  grid-area: app-title;
}
.app-description {
  grid-area: app-description;
}
.app-icons_grid {
  display: grid;
  gap: 1em;
  grid-area: app-icons_grid;
  grid-template-areas:
    "app-icon_1 app-icon_2 app-icon_3"
    "app-icon_4 app-icon_5 app-icon_6";
}
.app-icon_1 {
  grid-area: app-icon_1;
}
.app-icon_2 {
  grid-area: app-icon_2;
}
.app-icon_3 {
  grid-area: app-icon_3;
}
.app-icon_4 {
  grid-area: app-icon_4;
}
.app-icon_5 {
  grid-area: app-icon_5;
}
.app-icon_6 {
  grid-area: app-icon_6;
}
.QR-container {
  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  grid-area: QR;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  //transform: translateY(-50%);
}

.QR-container__temp {
  position: relative;
  grid-area: QR;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--accent-color);
  padding: 2em;
  border-radius: 1em;

  h3 {
    color: black;
    margin-bottom: 0.5em;
  }

  p {
    text-align: center;
  }

  img {
    width: 100px;
  }
}


.app-QR {
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 25px;
  width: 200px;
  height: 100%;
  border-radius: 6px;
  border: solid 1px var(--accent-color);
  img {
    width: 150px;
  }
}

.icon-store {
  margin-top: 2rem;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .QR-container {
    transform: translateY(7em);
    animation: QR-translate linear forwards;
    animation-timeline: view();
    animation-range: entry 0px;
  }
  @keyframes QR-translate {
    to { transform: translateY(0em);}
  }
}
  */
@media (max-width: 480px) {
  .app-grid {
    grid-template-columns: 1fr ;
    grid-template-rows: auto;
    grid-template-areas:
      "app-title"
      "app-description"
      "QR"
      "app-icons_grid";
  }
  .app-icons_grid {
    display: grid;
    gap: 1em;
    grid-area: app-icons_grid;
    grid-template-areas:
      "app-icon_1 app-icon_2"
      "app-icon_3 app-icon_4"
      "app-icon_5 app-icon_6";
  }
  .QR-container {
    gap: 2em;
    flex-direction: column;
  }
  .app-QR {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .app-QR > img {
    width: 100%;
  }
  .icon-store {
    margin-top: 0rem;
  }
  .app-QR > img:first-child {
    display: none;
  }
  .app-list {
    flex-wrap: wrap;
   }
   
}
/* Small devices (phones, 481px and up) */
@media (min-width: 481px) and (max-width: 820px) {
  /* Your styles here */
  .app-grid {
    grid-template-columns: 1fr ;
    grid-template-rows: auto;
    grid-template-areas:
      "app-title"
      "app-description"
      "app-icons_grid"
      "QR";
  }
  .app-icons_grid {
    display: grid;
    gap: 1em;
    grid-area: app-icons_grid;
    grid-template-areas:
      "app-icon_1 app-icon_2"
      "app-icon_3 app-icon_4"
      "app-icon_5 app-icon_6";
  }
}