.scroll-UI {
    opacity: 1;

    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    //bottom: 2em;

    &__wrapper {
        position: relative;
        width: 1px;
        height: 40px;
        //background-color: red;
        overflow-y: hidden;
    }

    &__line {
        position: absolute;
        
        width: 1px;
        height: 40px;
        background-color: white;
        animation: animate-scroll 1.0s ease-in-out infinite;
    }

    &__text {
        display: none;
        color: white;
    }


}

@keyframes animate-scroll {
0% {
    top: -50px;
    //transform: translate(-100%);
}
100% {
    top: 40px;
    //transform: translate(0);
}
}