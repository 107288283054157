.reveal-animation-black > span {
    color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(90deg, #222, #222);
}
.reveal-animation-accent > span {
  color: rgba(201, 179, 155, 0);
  background-image: linear-gradient(90deg, #C9B39B, #C9B39B);
}

.reveal-animation-white > span {
    color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(90deg, white, white);
}

.reveal-animation > span {
    
    background-clip: text;
    background-repeat: no-repeat;
    background-size: 0% 100%;

}