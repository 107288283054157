.brand {

    &-wrapper {
        padding-top: 6em;
        padding-bottom: 6em;
    }
    &-wrapper::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: var(--accent-color);
        opacity: 0.3;
    }

    &-item {
        height: 60px;
        margin-left: 150px;
    }

    &-flex {
        display: flex;
        //gap: 4em;
    }

}

.scroller {
    overflow-x: hidden;
    max-width: 100%;
}

.scroller-inner {
    width: max-content;
    display: flex;
    //gap: 4em;
}
/*
.scroller-left {
    transform: translate(calc(140% - 2em));
    animation: scroll-left linear;
    animation-timeline: scroll(root);
    animation-range-start: contain;
    animation-range-end: contain;
}
.scroller-right {
    transform: translate(calc(-50% - 2em));
    animation: scroll-right linear;
    animation-timeline: scroll(root);
    animation-range-start: contain;
    animation-range-end: contain;
}


@keyframes scroll-left {
    to {
        transform: translate(calc(-150% - 2em));
    }
}
@keyframes scroll-right {
    to {
        transform: translate(calc(0% + 2em));
    }
}
*/