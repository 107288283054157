.member-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 6em;
  padding-bottom: 6em;
}

.member-grid {
  display: grid;
  gap: 2em;
  //justify-items: start;
  grid-template-columns: 400px 1fr ;
  grid-template-rows: auto;
  grid-template-areas:
    "member-title member-title"
    "member-description member-icons_grid";
}

.member-title {
  grid-area: member-title;
}
.member-description {
  grid-area: member-description;
}

//****************************************//
// CODE ATTENTION A QUITTER QUAND QR CODE DISPO
//****************************************//

.member-description > :last-child {
  margin-top: 2em;
}

.member-icons_grid {
  display: grid;
  gap: 1em;
  grid-area: member-icons_grid;
  grid-template-areas:
    "member-icon_1 member-icon_2"
    "member-icon_3 member-icon_4";
}
.member-icon_1 {
  grid-area: member-icon_1;
}
.member-icon_2 {
  grid-area: member-icon_2;
}
.member-icon_3 {
  grid-area: member-icon_3;
}
.member-icon_4 {
  grid-area: member-icon_4;
}

@media (max-width: 480px) {
  .member-grid {
    display: flex;
    flex-direction: column;
  }
  .member-icons_grid {
    display: grid;
    gap: 1em;
    grid-area: member-icons_grid;
    grid-template-areas:
      "member-icon_1"
      "member-icon_2"
      "member-icon_3"
      "member-icon_4";
  }
}
/* Small devices (phones, 481px and up) */
@media (min-width: 481px) and (max-width: 820px) {
  /* Your styles here */
  .member-grid {
    grid-template-columns: 1fr ;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "member-title"
      "member-description"
      "member-icons_grid";
  }
}