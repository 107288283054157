.priority {

  &-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 1em;
    padding-bottom: 7em;
  }

  &-grid {
    display: grid;
    gap: 2em;
    grid-template-columns: 530px 1fr ;
    grid-template-rows: auto 1fr;

    padding-top: 6em;
  }
  &-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-list {
      height: 100%;
    }
  }
  &-title > :first-child {
    margin-bottom: 1em;
  }
 
  &-description {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  
}

@media (max-width: 480px) {
  .priority-grid {
    grid-template-columns: 1fr ;
    //grid-template-rows: 1fr 1fr 1fr;
  }
  
}
/* Small devices (phones, 481px and up) */
@media (min-width: 481px) and (max-width: 820px) {
  /* Your styles here */
  .priority-grid {
    grid-template-columns: 1fr ;
    grid-template-rows: auto 1fr;

  }

}