.icon-small__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  border: solid 1px var(--accent-color);
  aspect-ratio: 1/1;

  img {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
}

.icon-small__item {
  position: relative;
  

  h4, h5 {
    margin: 0;
    padding: 0;
  }
  h4 {
    margin: 0.0em 0em;
    
  }
  h5 {
    margin-top: 0.5em;
  }
}
.icon-small__center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-small__left {
    display: flex;
   // flex-direction: row;
    align-items: center;
}
.icon-small__margin___center {
    margin: 0 0.5em;
    margin-top: 1.0em;
}
.icon-small__margin___left {
  
    margin-left: 1em;
}
/*
@media (prefers-reduced-motion: no-preference) {

    .icon-small__icon {
      transform: scale(0) rotate(180deg);
      animation: scale-in linear forwards;
      animation-timeline: view();
      animation-range-start: 50px;
      animation-range-end: 150px;
    }

    @keyframes scale-in {
      to { transform: scale(1) rotate(0deg);}
    }
  }
    */