.form {

    margin-bottom: 4em;

    &-field {
        display: flex;
        flex-direction: column;

        input {
            appearance: none;
            border-radius: 6px;
            border: 1px solid var(--accent-color);
            border-color: var(--accent-color);
            padding: 1em;
            height: 50px;
        }
        input::placeholder {
            color: Black;
          }
        label {
            margin-bottom: 0.5em;
        }

        h5 {
            margin-top: 0.5em;
        }
    }

    &-dropdown {
        
        display: flex;
        gap: 1em;
        align-items: center;
        margin-top: 1em;
        

        
        //flex-direction: column;
        select {
            appearance: none;
            margin-top: 1em;
            border-radius: 6px;
            border-color: var(--accent-color);
            padding: 1em;
            height: 50px;
            cursor: pointer;
            //font-size: 1.15rem;
            width: 100%;
        }
        
    }
    &-select {
        h5 {
            margin-top: 0.5em;
        }
    }
    &-slider {
        position: relative;
        display: flex;
        align-items: center;
    }
    &-title {
        position: relative;
        display: flex;
        align-items: center;
    }

    &-button {
        background: blue;
        padding: 1em;
    }

    &-col {
        //margin-top: 1em;
        display: flex;
        gap: 2em;

        & > * {
            width: 100%;
            flex: 1 1 auto;
        }
    }

    &-spacing > * + * {
        padding-top: 2em;
        h5 {
            margin-top: 0.5em;
        }
    }

    &-date {

        &_content {
            margin-top: 0.8em;
            margin-bottom: 0.5em;
            display: flex;
            gap: 2em;
            & > * {
                flex: 1 1 auto;
            }
        }

    }
    &-checkbox {
        margin-top: 3em;
        display: flex;
        align-items: flex-start;
    }

    &-role, &-body {
        margin-top: 2em;
    }
}

.range-circle {
    position: relative;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    aspect-ratio: 1/1;

    margin: 10px 0px 10px 10px;

    h4 {
        color: black;
    }
    input {
        appearance: none;
        border:0;
        font-size: 20px;
        text-align: center;
        width: 50px;
        z-index: 10;
        background: transparent;
    }
}

.range-circle::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--accent-color);
}

.range-text + * {
    margin-left: 0;
}

#range-slider-yellow {
//margin: 2em 0;
//margin-top: 0.5em;
background: #e0dcda;
}

#range-slider-yellow .range-slider__range {
background: var(--accent-color);
//transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
background: var(--accent-color);
width: 10px;
height: 10px;
//transition: transform 0.3s;
}
.single-thumb .range-slider__thumb[data-lower] {
width: 0;
}
.single-thumb .range-slider__range {
border-radius: 6px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: flex;
    width: 100%;
}
.react-datepicker__input-container, .react-datepicker__input-container input {
    height: 60px;
}
.react-datepicker-wrapper {
    z-index: 600;
}

@media (max-width: 480px) {
    .form-col {
        flex-wrap: wrap;
    }
    .form-checkbox {
        align-items: flex-start;
        flex-direction: column;
    }
    .form-dropdown {
        flex-direction: column; 
    }
}
  /* Small devices (phones, 481px and up) */
@media (min-width: 481px) and (max-width: 820px) {
    .form-col {
        flex-wrap: wrap;
    }

}