
.hero {
    position: relative;
    width: 100%;

    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #000;
    }

    &-image {
        opacity: 0.2;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-text {
        position: absolute;
        text-align: center;
        & > * {
            margin-top: 1em;
        }
        
        //transform: translateY(-5vh);
        //background: red;
    }
}
