.alert {
  position: fixed;
  z-index: 50;
  top: 0;
  width: 100%;
  height: 200px;
  background: #248320;

  &_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 86px;
      height: 114px;
      //background: red;
      p {
          margin-top: 0.5em;
      }
  }
}