.dropdown {
    margin-top:1em;
    //display:inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    h4 {
        margin: 0;
    }
}

.ant-select {
    width: 100%!important;
    margin:0em!important;
    display: flex!important;
    align-items: center!important;
}

.ant-select-selector {

        appearance: none!important;
        border-radius: 6px!important;
        border: 1px solid var(--accent-color)!important;
        border-color: var(--accent-color)!important;
        margin:0em!important;
        padding: 1em!important;
        width: 100%!important;
        height: 50px!important;
}

.ant-select-selection-placeholder {

        color: black!important;

}

.ant-select-arrow {
    color: var(--accent-color)!important;
    opacity: 1!important;
}