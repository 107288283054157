.radio-group {
    display: flex;
    align-items: center;
}

.radio-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-top: 3px;
    padding-bottom: 3px;
    margin-left: 1em;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    color: black!important;
}

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio {
    position: absolute;
    top: -3px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--accent-color);
}

.radio-container:hover input ~ .custom-radio {
    background-color: white;
}

.radio-container input:checked ~ .custom-radio {
    background-color: white;
    animation: scaleUp 0.2s ease-in-out;
}

.custom-radio:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-container input:checked ~ .custom-radio:after {
    display: block;
    animation: scaleIn 0.3s ease-in-out forwards;
}

.radio-container .custom-radio:after {
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--accent-color);
    transform: scale(0); /* Start with scale 0 for animation */
}

.radio-container.checked {
    color: white;
}

@keyframes scaleUp {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}
@keyframes scaleIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}