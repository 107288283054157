.text-field, .text-area {
    display: flex;
    flex-direction: column;

    //background: red;

    input, textarea {
        appearance: none;
        border-radius: 6px;
        border: 1px solid var(--accent-color);
        border-color: var(--accent-color);
        padding: 1em;
        height: 50px;
    }
    input::placeholder {
        color: Black;
      }
    label {
        margin-bottom: 0.5em;
    }

    h5 {
        margin-top: 0.5em;
    }
}

textarea  
{  
    font-family: 'Poppins', sans-serif;  
   font-size: 14px;   
}