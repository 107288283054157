.header {
  opacity: 1;
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  
  &-content {
    position: relative;
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    background: rgba($color: #000000, $alpha: 0.9);
    pointer-events: auto;
    transform: translate(0%, 0%);
  }

  &-menu, &-lang, &-social {
      display: flex;

      & > * {
          margin: 2em 1em;
      }
      a {
        font-size: 16px;
      }
      
  }
  &-left {
    display: flex;

  }
  &-right {
    display: flex;
  }
  &-burger {
    position: fixed;
    z-index: 2001;
    width: 60px;
    height: 60px;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.9);
    border-radius: 0 0 0 20px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-burger__circle {
    position: relative;
    width: 4px;
    height: 4px;
    background: white;
    border-radius: 50%;
    margin: 2px;
  }
}
.active {
  position: relative;
  color: var(--accent-color);
}
.active::before {
  content: '';
    position: absolute;
    transform: scaleX(1);
    transform-origin: center;
    bottom: -20px;
    width: 100%;
    height: 2px;
    background-color: var(--accent-color);
}
.header-menu_link:is(.active) {
  color: var(--accent-color);
  cursor: default;
}

.header-menu_link:not(.active) {
  position: relative;
  color: white;
  &::before {
    content: '';
    position: absolute;
    transform: scaleX(0);
    transform-origin: center;
    bottom: -20px;
    width: 100%;
    height: 2px;
    background-color: var(--accent-color);
  }
}
.header-menu_link:not(.active):hover {
  &::before {
    animation: 0.3s ease-out forwards link-in;
  }
}
.header-menu_link:not(.active):not(:hover) {

  &::before {
    transform: scaleX(1);
    animation: 0.3s ease-out forwards link-out;
  }
}

button{
  background: none;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

li {
  position: relative;
}

a {
  color: white;
}

.icon-social {
  height: 15px;
}

/*
.header-animation {
  opacity: 0;
  background: rgba($color: #000000, $alpha: 0.0);
  animation: 3s linear forwards header-in;
  animation-timeline: view();
  animation-range-start: exit 40vh;
  animation-range-end: exit 60vh;
}
@keyframes header-in {
  to { background: rgba($color: #000000, $alpha: 0.9); }
}
*/
@keyframes link-in {
  to { transform: scaleX(1) }
}
@keyframes link-out {
  to { transform: scaleX(0) }
}

/* Small devices (phones, 481px and up) */
@media (min-width: 0px) and (max-width: 820px) {
  /* Your styles here */
  .header-content {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transform: translate(0%, -100%);
  }
  .header-menu {
    flex-direction: column;
    align-items: center;
    a {
      font-size: 20px;
    }
  }
  .header-lang {
    grid-area: lang;
    justify-self: center;
  }
  .header-social {
    grid-area: social;
    justify-self: end;

    .text-white {
      display: none;
    }
  }
  .header-right {
    flex-direction: column-reverse;
    align-items: center;
  }
  .header-burger {
    display: flex;
  }
}

@media (min-width: 821px) {
  .header-content {
    transform: translate(0px, 0%) !important;
  }
}