:root {
  --animation-speed: 0.1s;
}

button, a {
    position: relative;
    
    h4 {
      font-weight: 500;
    }
}
.button-primary {
  position: relative;
  overflow: hidden;
}

.button-primary_active::after {
  content: '';
  position: absolute;
  transform: scaleX(0);
  bottom: 0;
  left: 0; 
  width: 100%;
  height: 3px;
  background: var(--accent-color);
  animation: var(--animation-speed) ease-out forwards hover;
}
.button-primary_inactive::after {
  content: '';
  position: absolute;
  transform: scaleX(1);
  bottom: 0;
  left: 0; 
  width: 100%;
  height: 3px;
  background: var(--accent-color);
  animation: var(--animation-speed) ease-out forwards leave;
}
.button-text_up {
  h4 {
    transform: translateY(0px);
    animation: var(--animation-speed) ease-out forwards text-up;
  }
}
.button-text_down {
  h4 {
    transform: translateY(-2px);
    animation: var(--animation-speed) ease-out forwards text-down;
  }
}
.button-primary_stroke {
  position: relative;
}
.button-primary_stroke::before {
  content: '';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px var(--accent-color);
  animation: 1s forwards fade-in;
}

.button-wrapper {
    grid-area: button;
    position: relative;
  }
  
.stroke {
  position: absolute; 
  height: 100%; 
  width: 100%; 
  left: 0; 
  top: 0;
}

@keyframes hover {
  to { transform: scaleX(1) }
}
@keyframes leave {
  to { transform: scaleX(0) }
}
@keyframes text-up {
  to { transform: translateY(-2px) }
}
@keyframes text-down {
  to { transform: translateY(0px) }
}
@keyframes fade-in {
  to { opacity: 1 }
}