.logo {
    opacity: 0;
    width: 200px;
    position: relative;

    &-wrapper {
        position: relative;
    }

    &-loader {
        position: absolute;
        top: 0;
        left: 0;
    }
    &-loader__rect {
        fill:none;
        stroke:#EBE5D6;
        //stroke:red;
        stroke-width:7;
        stroke-miterlimit:7;
        stroke-dasharray: 0 624;
        //stroke-dashoffset: 0;
        //animation: mask 4s forwards ease-in-out;
    }

}